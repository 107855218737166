import { css } from '@emotion/react';

type PropsType = {
  children?: React.ReactNode;
  className?: string;
};

export function Content({ children, className }: PropsType) {
  return (
    <div className={className} css={styles.base}>
      {children}
    </div>
  );
}

const styles = {
  base: css`
    display: flex;
    flex: 1 1 0%;
    min-width: 0;
    width: 100%;
  `,
};
