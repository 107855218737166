import { css } from '@emotion/react';

type PropsType = {
  children?: React.ReactNode;
  className?: string;
};

export function Main({ children, className }: PropsType) {
  return (
    <main className={className} css={styles.base}>
      {children}
    </main>
  );
}

const styles = {
  base: css`
    flex: 1 1 0%;
    min-width: 0;
  `,
};
