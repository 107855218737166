import { css } from '@emotion/react';

type HeadingSizeType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type PropsType = {
  children: React.ReactNode;
  className?: string;
  size?: HeadingSizeType;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
};

export function Heading({ children, className, size, tag = 'h2' }: PropsType) {
  const HeadingTag = tag;
  return (
    <HeadingTag className={className} css={styles.base({ size })}>
      {children}
    </HeadingTag>
  );
}

const styles = {
  base: ({ size }: { size?: HeadingSizeType }) => css`
    font-weight: 600;
    ${size === 'h1' && `font-size: 28px;`}
    ${size === 'h2' && `font-size: 24px;`}
    ${size === 'h3' && `font-size: 20px;`}
    ${size === 'h4' && `font-size: 18px;`}
    ${size === 'h5' && `font-size: 16px;`}
    ${size === 'h6' && `font-size: 14px;`}
  `,
};
