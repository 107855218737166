import { css } from '@emotion/react';
import { Container } from '~/components/templates/Container';
import { HeadingSection } from '~/components/atoms/HeadingSection';

type PropsType = {
  className?: string;
};

export function Price({ className }: PropsType) {
  return (
    <section className={className} css={styles.base}>
      <Container>
        <HeadingSection size="h2" css={styles.heading}>
          ご利用料金
        </HeadingSection>
        <div css={styles.inner}>
          <dl css={styles.list.base}>
            <dt css={styles.list.title}>月額（税別）</dt>
            <dd css={styles.list.description}>50,000円〜</dd>
          </dl>
          <p css={styles.note}>月間50万PVを超える場合は別途お見積り</p>
        </div>
      </Container>
    </section>
  );
}

const styles = {
  base: css`
    background: var(--color-green-10);
    padding: 32px 0 61px;
  `,
  heading: css`
    margin-bottom: 48px;
  `,
  inner: css`
    margin: 0 auto;
    max-width: 415px;
  `,
  list: {
    base: css`
      border-bottom: 1px solid var(--color-gray-rain);
      display: flex;
      font-size: 24px;
      line-height: ${32 / 24};
      padding: 0 24px 11px;
      & + & {
        margin-top: 24px;
      }
    `,
    title: css`
      flex-shrink: 0;
      width: 210px;
    `,
    description: css`
      color: var(--color-green-120);
      flex: 1;
      font-weight: 600;
      min-width: 0;
    `,
  },
  note: css`
    color: var(--color-gray-ink);
    font-size: 12px;
    line-height: ${20 / 12};
    margin: 11px 0 0 234px;
    padding-left: 1.5em;
    position: relative;
    &::before {
      content: '※';
      left: 0;
      position: absolute;
      top: 0;
    }
  `,
};
