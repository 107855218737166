import { css } from '@emotion/react';
import { Container } from '~/components/templates/Container';
import { HeadingSection } from '~/components/atoms/HeadingSection';

type PropsType = {
  className?: string;
};

export function Company({ className }: PropsType) {
  return (
    <section className={className} css={styles.base}>
      <Container>
        <HeadingSection size="h2" css={styles.heading}>
          会社概要
        </HeadingSection>
        <dl css={styles.list.base}>
          <dt css={styles.list.title}>会社名</dt>
          <dd css={styles.list.description}>株式会社 PLAN-B</dd>
        </dl>
        <dl css={styles.list.base}>
          <dt css={styles.list.title}>設立</dt>
          <dd css={styles.list.description}>2003年 10月 22日</dd>
        </dl>
        <dl css={styles.list.base}>
          <dt css={styles.list.title}>代表取締役</dt>
          <dd css={styles.list.description}>鳥居本 真徳</dd>
        </dl>
        <dl css={styles.list.base}>
          <dt css={styles.list.title}>従業員数</dt>
          <dd css={styles.list.description}>
            210名 ※2023年12月現在（グループ会社含む）
          </dd>
        </dl>
        <dl css={styles.list.base}>
          <dt css={styles.list.title}>資本金</dt>
          <dd css={styles.list.description}>100,000,000 円</dd>
        </dl>
        <dl css={styles.list.base}>
          <dt css={styles.list.title}>事業内容</dt>
          <dd css={styles.list.description}>
            インハウスSEOツール「SEARCH WRITE」の開発・提供
            <br />
            デジタルマーケティングコンサルティング
            <br />
            オウンドメディア運営
          </dd>
        </dl>
        <dl css={styles.list.base}>
          <dt css={styles.list.title}>所在地</dt>
          <dd css={styles.list.description}>
            <ul>
              <li>
                大阪本社：〒550-0013
                <br />
                大阪市西区新町 1-28-3 四ツ橋グランスクエア 6階
              </li>
              <li>
                東京本社：〒141-0022
                <br />
                東京都品川区東五反田2-5-9 CIRCLES with 島津山 3階
              </li>
            </ul>
          </dd>
        </dl>
      </Container>
    </section>
  );
}

const styles = {
  base: css`
    background: var(--color-gray-light);
    padding: 32px 0 94px;
  `,
  heading: css`
    margin-bottom: 48px;
  `,
  list: {
    base: css`
      border-bottom: 1px solid var(--color-gray-rain);
      display: flex;
      font-size: 14px;
      line-height: ${32 / 14};
      padding: 16px 0;
    `,
    title: css`
      border-right: 1px solid var(--color-gray-rain);
      flex-shrink: 0;
      padding: 0 16px;
      width: 161px;
    `,
    description: css`
      flex: 1;
      min-width: 0;
      padding: 0 16px;
      li + li {
        margin-top: 16px;
      }
    `,
  },
};
