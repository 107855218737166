import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TopPage } from '~/pages/index';

export function App() {
  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s | ${process.env.REACT_APP_SITENAME}`} />
      <TopPage />
    </HelmetProvider>
  );
}
