import { css } from '@emotion/react';
import { LogoCompany } from '~/components/atoms/LogoCompany';

export function Footer() {
  return (
    <footer css={styles.base}>
      <div css={styles.inner}>
        <p>
          <a href="https://www.plan-b.co.jp/" target="_blank" rel="noreferrer">
            <LogoCompany css={styles.logo} />
          </a>
        </p>
        <ul css={styles.nav.list}>
          <li css={styles.nav.item}>
            <a
              href="https://searchwrite.jp/terms/"
              target="_blank"
              rel="noreferrer"
              css={styles.nav.link}>
              利用規約
            </a>
          </li>
          <li css={styles.nav.item}>
            <a
              href="https://searchwrite.jp/privacypolicy/"
              target="_blank"
              rel="noreferrer"
              css={styles.nav.link}>
              プライバシーポリシー
            </a>
          </li>
          <li css={styles.nav.item}>
            <a
              href="https://searchwrite.jp/law/"
              target="_blank"
              rel="noreferrer"
              css={styles.nav.link}>
              特定商取引法に関する表示
            </a>
          </li>
        </ul>
        <p>Copyright &copy; PLAN-B Inc.</p>
      </div>
    </footer>
  );
}

const styles = {
  base: css`
    background: var(--color-gray-ink);
    color: var(--color-white);
    font-size: 10px;
    line-height: 1;
    padding: 22px var(--inset-x-content);
    position: relative;
  `,
  inner: css`
    align-items: center;
    display: flex;
    gap: 32px;
  `,
  logo: css`
    width: 105px;
  `,
  nav: {
    list: css`
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      gap: 16px 32px;
      min-width: 0;
    `,
    item: css`
      min-width: 0;
    `,
    link: css`
      color: var(--color-white);
    `,
  },
};
