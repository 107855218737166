import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Heading } from '~/components/atoms/Heading';

export function SideForm() {
  const formRef = useRef<HTMLFormElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef<number | null>(null);
  const isMountedRef = useRef(false);
  const [isReady, setReady] = useState(false);

  const scrollSticky = useCallback(() => {
    if (!stickyRef.current) return;

    const stickyRect = stickyRef.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset;
    const delta =
      lastScrollTop.current === null ? 0 : scrollTop - lastScrollTop.current;

    if (stickyRect.height > window.innerHeight) {
      let top = stickyRect.top - delta;
      top = Math.max(window.innerHeight - stickyRect.height, top);
      top = Math.min(0, top);
      stickyRef.current.style.top = top + 'px';
    } else {
      stickyRef.current.style.top = '';
    }
    lastScrollTop.current = scrollTop;
  }, []);

  const customizeForm = useCallback(() => {
    if (!stickyRef.current || !formRef.current) return;

    const rowsEl = formRef.current.querySelectorAll('.mktoFormRow');

    rowsEl.forEach((el) => {
      const inputEl = el.querySelector('input,select');
      const textEl = el.querySelector(
        'input:not([type="checkbox"]):not([type="radio"])'
      );
      const selectEl = el.querySelector('select');
      const name = inputEl?.getAttribute('name') ?? '';

      if (name) {
        el.classList.add(name.toLowerCase());
      }
      if (textEl) {
        if (textEl.getAttribute('type') !== 'hidden') {
          el.classList.add('noLabel');

          const labelEl = el.querySelector('.mktoLabel');
          if (labelEl) {
            const placeholder = labelEl.textContent ?? '';
            textEl.setAttribute('placeholder', placeholder.replace('*', ''));
          }
        }
      }
      if (selectEl) {
        el.classList.add('noLabel');
        selectEl.closest('.mktoFieldWrap')?.classList.add('selectWrap');
      }
    });
  }, []);

  useEffect(() => {
    if (isMountedRef.current) return;
    isMountedRef.current = true;

    MktoForms2.loadForm('//lp.plan-b.co.jp', '103-LGZ-382', 2329);
    MktoForms2.whenReady(() => {
      customizeForm();
      scrollSticky();
      setReady(true);
    });
  }, [customizeForm, scrollSticky]);

  useEffect(() => {
    scrollSticky();
    window.addEventListener('resize', scrollSticky);
    window.addEventListener('scroll', scrollSticky);

    return () => {
      window.removeEventListener('resize', scrollSticky);
      window.removeEventListener('scroll', scrollSticky);
    };
  }, [scrollSticky]);

  return (
    <div css={styles.base}>
      <div css={styles.sticky({ isReady })} ref={stickyRef}>
        <div css={styles.inner}>
          <Heading tag="h2" size="h2" css={styles.heading}>
            資料ダウンロード(無料)
          </Heading>
          <div css={styles.content}>
            <form id="mktoForm_2329" ref={formRef} />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  base: css`
    flex-shrink: 0;
    position: relative;
    width: var(--width-side);
  `,
  sticky: ({ isReady }: { isReady: boolean }) => css`
    align-items: center;
    background: var(--color-white);
    display: flex;
    min-height: 100vh;
    opacity: 0;
    position: sticky;
    right: 0;
    top: 0;
    transition: opacity 1000ms;
    width: var(--width-side);
    ${isReady && `opacity: 1;`}
  `,
  inner: css`
    flex: 1;
    min-width: 0;
    padding: 60px 32px;
  `,
  heading: css`
    margin-bottom: 12px;
    text-align: center;
  `,
  content: css`
    .mktoForm {
      width: 100% !important;
      .mktoFormRow {
        clear: none;
        margin-bottom: 8px;
        position: relative;
        &.noLabel {
          .mktoLabel {
            display: none;
          }
        }
        &.lastname {
          float: left;
          width: 128px;
        }
        &.firstname {
          float: right;
          width: 128px;
        }
        &.firstname + .mktoFormRow {
          clear: both;
        }
        &.agree_personal_information_policy {
          margin-top: 15px;
        }
        &.agree_personal_information_policy,
        &.opt_in {
          .mktoFieldDescriptor {
            .mktoFieldWrap {
              align-items: center;
              display: flex;
              flex-direction: row-reverse;
              justify-content: left;
              float: left;
            }
            .mktoLabel {
              color: var(--color-gray-cloud);
              font-size: 12px;
              font-weight: 400;
              line-height: ${24 / 12};
              margin: 0;
            }
            .mktoCheckboxList {
              > input {
                float: none;
                top: 0.3em;
              }
              > label {
                min-height: 0;
              }
            }
          }
        }
        .mktoHtmlText {
          a {
            margin-left: 20px;
          }
        }
      }
      .mktoRequiredField .mktoAsterix,
      .mktoOffset,
      .mktoGutter {
        display: none;
      }
      .mktoFormCol {
        float: none;
        margin-bottom: 0 !important;
        min-height: 0;
      }
      .mktoFieldWrap {
        float: none;
        input:not([type='checkbox']):not([type='radio']),
        select {
          background: var(--color-white);
          border-bottom: 1px solid #7acca8;
          font-size: 16px;
          height: 40px;
          line-height: ${24 / 16};
          padding: 0;
          width: 100% !important;
          &::placeholder {
            color: var(--color-gray-rain);
          }
        }
        input:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 100px #fff inset !important;
        }
        &.selectWrap {
          position: relative;
          &::before {
            border-color: transparent;
            border-top-color: var(--color-main-sw);
            border-width: 8px 4px 0;
            content: '';
            display: block;
            pointer-events: none;
            position: absolute;
            right: 16px;
            top: 16px;
            z-index: 1;
          }
          select {
            appearance: none;
            padding-right: 25px;
          }
        }
      }
      .mktoField {
        float: none;
      }
      .mktoLabel {
        display: block;
        float: none;
        font-size: 14px;
        font-weight: 600;
        line-height: ${22 / 14};
        margin-bottom: 6px;
        padding: 0;
        width: auto !important;
      }
      .mktoCheckboxList,
      .mktoRadioList {
        float: none;
        padding: 0;
        width: auto !important;
        > input {
          float: left;
          position: relative;
          top: 0.35em;
          vertical-align: top;
        }
        > label {
          font-size: 12px;
          line-height: ${18 / 12};
          margin: 0 0 5px 20px;
          min-height: 18px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .mktoHtmlText {
        font-size: 12px;
        line-height: ${18 / 12};
        width: auto !important;
        a {
          color: var(--color-main-sw) !important;
        }
      }
      .mktoError {
        bottom: auto !important;
        margin-top: -10px;
        max-width: 100%;
        right: 0 !important;
        top: 100%;
        .mktoErrorArrowWrap {
          border: solid transparent;
          border-bottom-color: var(--color-red);
          border-width: 0 4px 4px 4px;
          height: 0;
          left: auto;
          right: 25px;
          top: 0;
          width: 0;
        }
        .mktoErrorMsg {
          background: var(--color-red);
          border: none;
          border-radius: 5px;
          box-shadow: none;
          color: var(--color-white);
          font-size: 12px;
          font-weight: 400;
          line-height: ${15 / 12};
          margin-top: 3px;
          max-width: none;
          padding: 3px 10px;
          text-shadow: none;
        }
      }
      .mktoButtonRow {
        display: block;
        margin-top: 20px;
        text-align: center;
      }
      .mktoButtonWrap {
        margin: 0 !important;
      }
      .mktoButton {
        background: #feac1f;
        border-radius: 6px;
        color: var(--color-white);
        font-size: 18px;
        font-weight: 600;
        line-height: ${24 / 18};
        min-width: 240px;
        padding: 12px 20px;
      }
    }
  `,
};
