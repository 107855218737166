import { css } from '@emotion/react';
import { Container } from '~/components/templates/Container';
import { HeadingSection } from '~/components/atoms/HeadingSection';
import imgProblem01 from '~/assets/img/top/img-problem-01.svg';
import imgProblem02 from '~/assets/img/top/img-problem-02.svg';
import imgProblem03 from '~/assets/img/top/img-problem-03.svg';

type PropsType = {
  className?: string;
};

export function Problem({ className }: PropsType) {
  return (
    <section className={className} css={styles.base}>
      <Container>
        <HeadingSection size="h2" css={styles.heading}>
          オウンドメディア運営の課題
        </HeadingSection>
        <ul css={styles.list.base}>
          <li css={styles.list.item}>
            <img
              src={imgProblem01}
              alt=""
              width="120"
              height="108"
              css={styles.list.image}
            />
            <p css={styles.list.text}>
              流入数は増えたが
              <br />
              CV数が伸びない
            </p>
          </li>
          <li css={styles.list.item}>
            <img
              src={imgProblem02}
              alt=""
              width="108"
              height="120"
              css={styles.list.image}
            />
            <p css={styles.list.text}>
              CVRの上げ方が
              <br />
              分からない
            </p>
          </li>
          <li css={styles.list.item}>
            <img
              src={imgProblem03}
              alt=""
              width="120"
              height="120"
              css={styles.list.image}
            />
            <p css={styles.list.text}>
              CVR改善施策を打つ
              <br />
              リソースがない
            </p>
          </li>
        </ul>
      </Container>
    </section>
  );
}

const styles = {
  base: css`
    background: var(--color-gray-light);
    padding: 32px 0 64px;
    position: relative;
    &::before {
      border-color: transparent;
      border-top-color: var(--color-gray-light);
      border-width: 33px 65px 0;
      bottom: -32px;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      transform: translate(-50%, 0);
    }
  `,
  heading: css`
    margin-bottom: 32px;
  `,
  list: {
    base: css`
      display: flex;
      justify-content: center;
      gap: 38px;
      margin: 0 auto;
    `,
    item: css`
      flex: 1;
      min-width: 0;
    `,
    image: css`
      height: 120px;
      object-fit: scale-down;
      margin: 0 auto 24px;
    `,
    text: css`
      line-height: ${24 / 18};
      font-size: 18px;
      text-align: center;
    `,
  },
};
