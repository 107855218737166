import { Company } from '~/components/organisms/top/Company';
import { Content } from '~/components/templates/Content';
import { Feature } from '~/components/organisms/top/Feature';
import { Layout } from '~/components/templates/Layout';
import { Main } from '~/components/templates/Main';
import { Mv } from '~/components/organisms/top/Mv';
import { Price } from '~/components/organisms/top/Price';
import { Problem } from '~/components/organisms/top/Problem';
import { SideForm } from '~/components/molecules/SideForm';
import { Support } from '~/components/organisms/top/Support';

export function TopPage() {
  return (
    <Layout>
      <Content>
        <Main>
          <Mv />
          <Problem />
          <Feature />
          <Support />
          <Price />
          <Company />
        </Main>
        <SideForm />
      </Content>
    </Layout>
  );
}
