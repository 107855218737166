import { css } from '@emotion/react';

type HeadingSizeType = 'h2';

type PropsType = {
  children: React.ReactNode;
  className?: string;
  size?: HeadingSizeType;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
};

export function HeadingSection({
  children,
  className,
  size,
  tag = 'h2',
}: PropsType) {
  const HeadingTag = tag;
  return (
    <HeadingTag className={className} css={styles.base({ size })}>
      {children}
    </HeadingTag>
  );
}

const styles = {
  base: ({ size }: { size?: HeadingSizeType }) => css`
    text-align: center;
    ${size === 'h2' && `font-size: 32px; line-height: ${48 / 32};`}
  `,
};
