import { css } from '@emotion/react';
import { Footer } from '~/components/molecules/Footer';

type PropsType = {
  children?: React.ReactNode;
  className?: string;
};

export function Layout({ children, className }: PropsType) {
  return (
    <div className={className} css={styles.base}>
      {children}
      <Footer />
    </div>
  );
}

const styles = {
  base: css`
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    min-width: 0;
  `,
};
