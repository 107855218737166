import imgLogo from '~/assets/img/common/logo-black.svg';
import imgLogoWhite from '~/assets/img/common/logo-white.svg';

type PropsType = {
  className?: string;
  color?: 'white';
};

export function Logo({ className, color }: PropsType) {
  const src = color === 'white' ? imgLogoWhite : imgLogo;
  return (
    <img
      src={src}
      alt="SEARCH WRITE LEAD"
      width="527"
      height="44"
      className={className}
    />
  );
}
