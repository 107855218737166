import { css } from '@emotion/react';
import { Container } from '~/components/templates/Container';
import { Heading } from '~/components/atoms/Heading';
import { HeadingSection } from '~/components/atoms/HeadingSection';
import { Logo } from '~/components/atoms/Logo';
import iconCheck from '~/assets/img/common/icon-check.svg';
import imgFeature01 from '~/assets/img/top/img-feature-01.png';
import imgFeature02 from '~/assets/img/top/img-feature-02.png';
import imgFeature0301 from '~/assets/img/top/img-feature-03-01.png';
import imgFeature0302 from '~/assets/img/top/img-feature-03-02.png';

type PropsType = {
  className?: string;
};

export function Feature({ className }: PropsType) {
  return (
    <section className={className} css={styles.base}>
      <Container>
        <HeadingSection size="h2" css={styles.heading}>
          <Logo css={styles.logo} />
          サイト訪問時のニーズに沿った施策でCVRを向上
        </HeadingSection>
        <section css={styles.feature.base}>
          <div css={styles.feature.header}>
            <Heading tag="h3" size="h2" css={styles.feature.heading}>
              訪問者の関心に合わせてCV導線を設定
            </Heading>
          </div>
          <p>
            <img
              src={imgFeature01}
              alt="関心事に沿うことでCVを後押し"
              width="541"
              height="396"
              css={styles.feature.image}
            />
          </p>
        </section>
        <section css={styles.feature.base}>
          <div css={styles.feature.header}>
            <Heading tag="h3" size="h2" css={styles.feature.heading}>
              訪問者の関心が似た複数のページを一括管理し、工数を削減
            </Heading>
          </div>
          <img
            src={imgFeature02}
            alt=""
            width="721"
            height="273"
            css={styles.feature.image}
          />
          <p css={styles.feature02.text}>
            ページのまとまりごとに施策を管理できるので
            <br />
            少ない工数でPDCAを回しやすい
          </p>
        </section>
        <section css={styles.feature.base}>
          <div css={styles.feature.header}>
            <Heading tag="h3" size="h2" css={styles.feature.heading}>
              誰でも簡単に操作しやすく、PDCAを回しやすいUI
            </Heading>
          </div>
          <ul css={styles.feature03.list}>
            <li css={styles.feature03.item}>
              <p css={styles.feature03.text}>施策の成果をひと目で確認</p>
              <img
                src={imgFeature0301}
                alt=""
                width="331"
                height="448"
                css={styles.feature.image}
              />
            </li>
            <li css={styles.feature03.item}>
              <p css={styles.feature03.text}>CV導線を直感的に作成</p>
              <img
                src={imgFeature0302}
                alt=""
                width="331"
                height="448"
                css={styles.feature.image}
              />
            </li>
          </ul>
        </section>
      </Container>
    </section>
  );
}

const styles = {
  base: css`
    background: var(--color-green-10);
    padding: 64px 0 64px;
  `,
  logo: css`
    margin: 0 auto 24px;
    width: 289px;
  `,
  heading: css`
    margin-bottom: 62px;
  `,
  feature: {
    base: css`
      font-size: 16px;
      line-height: ${24 / 16};
      & + & {
        margin-top: 62px;
      }
    `,
    header: css`
      align-items: flex-start;
      display: flex;
      gap: 16px;
      margin-bottom: 30px;
      &::before {
        background: var(--color-green-120) url(${iconCheck}) no-repeat center
          center;
        border-radius: 50%;
        content: '';
        flex-shrink: 0;
        height: 32px;
        margin-top: 2px;
        width: 32px;
      }
    `,
    heading: css`
      flex: 1;
      min-width: 0;
    `,
    image: css`
      margin: 0 auto;
    `,
  },
  feature02: {
    text: css`
      margin-top: 13px;
      text-align: center;
    `,
  },
  feature03: {
    list: css`
      display: flex;
      gap: 47px;
      justify-content: center;
    `,
    item: css`
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 331px;
    `,
    text: css`
      flex: 1;
      text-align: center;
    `,
  },
};
