import { css } from '@emotion/react';
import { Container } from '~/components/templates/Container';
import { HeadingSection } from '~/components/atoms/HeadingSection';
import iconPlus from '~/assets/img/common/icon-plus.svg';
import imgSupport01 from '~/assets/img/top/img-support-01.png';
import imgSupport02 from '~/assets/img/top/img-support-02.png';
import bgSupport from '~/assets/img/top/bg-support.svg';

type PropsType = {
  className?: string;
};

export function Support({ className }: PropsType) {
  return (
    <section className={className} css={styles.base}>
      <Container>
        <HeadingSection size="h2" css={styles.heading}>
          メディア運営事業で蓄積したノウハウを還元
          <br />
          「自分たちでできる」まで無償サポート
        </HeadingSection>
        <ul css={styles.list.base}>
          <li css={styles.list.item}>
            <div css={styles.list.content}>
              <p css={styles.list.text}>
                <strong>CVRの上げ方</strong>
                <br />
                が分かる
              </p>
            </div>
            <img
              src={imgSupport01}
              alt=""
              width="261"
              height="261"
              css={styles.list.image}
            />
          </li>
          <li css={styles.list.item}>
            <div css={styles.list.content}>
              <p css={styles.list.text}>
                <strong>ツールの使い方</strong>
                <br />
                が分かる
              </p>
            </div>
            <img
              src={imgSupport02}
              alt=""
              width="261"
              height="261"
              css={styles.list.image}
            />
          </li>
        </ul>
        <div css={styles.result.base}>
          <p css={styles.result.text}>
            正しい運用方法までわかり
            <br />
            <strong>個人・チームが自走</strong>できるように
          </p>
        </div>
      </Container>
    </section>
  );
}

const styles = {
  base: css`
    background: var(--color-gray-light) url(${bgSupport}) no-repeat center
      calc(100% - 64px);
    padding: 32px 0 280px;
  `,
  heading: css`
    margin-bottom: 64px;
  `,
  list: {
    base: css`
      display: flex;
      gap: 144px;
      justify-content: center;
    `,
    item: css`
      display: flex;
      flex-direction: column;
      position: relative;
      width: 280px;
      & + & {
        div:first-of-type {
          position: relative;
          &::before {
            background: url(${iconPlus}) no-repeat;
            content: '';
            display: block;
            height: 48px;
            left: -97.5px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            width: 48px;
          }
        }
      }
    `,
    content: css`
      background: var(--color-white);
      border: 1.5px solid var(--color-green-120);
      border-radius: 9999px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding: 15px 30px;
      position: relative;
      text-align: center;
    `,
    text: css`
      font-size: 16px;
      line-height: ${32 / 16};
      strong {
        font-size: 24px;
        line-height: ${32 / 24};
      }
    `,
    image: css`
      margin: -33px auto 0;
    `,
  },
  result: {
    base: css`
      margin-top: 32px;
      text-align: center;
      &::before {
        border-color: transparent;
        border-top-color: var(--color-main-sw);
        border-width: 24px 24px 0;
        content: '';
        display: inline-block;
        margin: 0 auto 26px;
      }
    `,
    text: css`
      font-size: 24px;
      line-height: ${48 / 24};
    `,
  },
};
