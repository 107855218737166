import { css } from '@emotion/react';
import { Container } from '~/components/templates/Container';
import { Logo } from '~/components/atoms/Logo';
import imgMv from '~/assets/img/top/img-mv.svg';

type PropsType = {
  className?: string;
};

export function Mv({ className }: PropsType) {
  return (
    <div className={className} css={styles.base}>
      <Container>
        <h1 css={styles.heading}>
          <Logo css={styles.logo} />
        </h1>
        <p css={styles.text}>
          <strong>オウンドメディア</strong>の<strong>CVR</strong>を改善する
          <br />
          <strong>WEBコミュニケーション</strong>ツール
        </p>
        <img src={imgMv} alt="" width="499" height="232" css={styles.image} />
      </Container>
    </div>
  );
}

const styles = {
  base: css`
    align-items: center;
    background: var(--color-green-10);
    display: flex;
    min-height: 100vh;
    padding: 64px 0 56px;
  `,
  heading: css`
    margin-bottom: 42px;
  `,
  logo: css`
    width: 383px;
  `,
  text: css`
    font-size: 32px;
    line-height: ${82 / 32};
    strong {
      font-size: 48px;
      line-height: ${82 / 48};
    }
  `,
  image: css`
    margin: 36px auto 0;
  `,
};
