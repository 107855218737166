import { css } from '@emotion/react';

type PropsType = {
  children: React.ReactNode;
  className?: string;
};

export function Container({ children, className }: PropsType) {
  return (
    <div className={className} css={styles.base}>
      {children}
    </div>
  );
}

const styles = {
  base: css`
    margin-left: auto;
    margin-right: auto;
    max-width: calc(var(--width-container) + var(--inset-x-content) * 2);
    padding-left: var(--inset-x-content);
    padding-right: var(--inset-x-content);
    width: 100%;
  `,
};
