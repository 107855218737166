import imgLogoCompany from '~/assets/img/common/logo-company-white.svg';

type PropsType = {
  className?: string;
};

export function LogoCompany({ className }: PropsType) {
  return (
    <img
      src={imgLogoCompany}
      alt="株式会社PLAN-B"
      width="352"
      height="62"
      className={className}
    />
  );
}
